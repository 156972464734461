import { SessionProvider } from "next-auth/react";
import "@styles/globals.scss";
import { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import type { AppProps } from "next/app";
import { ConfigProvider } from "antd";
// import { requestGlobalComponents } from "@src/server/utils/layout";
import { ePalletTheme } from "../themes/ePalletTheme";
import { LayoutData } from "@types";
import { Layout } from "@components/Layout";
import { AppProvider } from "../libs/context";
import NProgress from "nprogress";
import GeneralInformation from "src/components/GeneralInformation";
import EpalletLogin from "src/components/organsim/EpalletLogin";
import SignUpPage from "src/components/organsim/SignUp/SignUpPage";
import RestorePassword from "~/src/components/organsim/EpalletLogin/RestorePassword";
import { hotjar } from "react-hotjar";
import Script from "next/script";
import Head from "next/head";
import { cleanPageURL, getCookie } from "../server/utils/commonUtils";
import gaScrolling from "../libs/utils/gaScrolling";
import VerifyEmailModal from "@components/shared/VerifyEmailModal";
import { ExpirationModal } from "src/components/shared/ExpirationModal";
import { Provider } from "react-redux";
import { store } from "../store";
import DefaultZipCode from "../components/DefaultZipCode";
import NoInternetConnection from "../components/shared/NoInternetConnection";

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: true,
});

interface AppPageProps extends AppProps {
  layoutData: LayoutData[];
  pageProps: any;
}
function MyApp({ Component, layoutData, pageProps }: AppPageProps) {
  let userID = getCookie("userID");
  const [mode, setMode] = useState<"light" | "dark" | undefined>();
  const router = useRouter();
  const { query, pathname, asPath } = useRouter();
  const complete_profile = query?.complete_profile;
  const type = query?.type;
  const uid = query?.uid || "";
  const token = query?.token || "";
  const epallet_login = query?.epallet_login;
  const epallet_signup = query?.epallet_signup;
  const source_path = query?.source_path || "";
  const haggleTableInfo = layoutData?.filter(
    (props: any) => props.id === "HaggleTable"
  );
  const haggleTableData: any = haggleTableInfo?.[0];
  const haggleTableProps: any = haggleTableData?.params;

  const LayoutComponent =
    (type === "RESTORE_PASS" || type === "ADDITIONAL_USER") && uid && token ? (
      <RestorePassword token={token} uid={uid} type={type} />
    ) : (type === "EMAIL_VERIFICATION" && uid) || complete_profile ? (
      <>
        <GeneralInformation
          token={token}
          uid={uid}
          haggleTableProps={haggleTableProps}
        />
      </>
    ) : epallet_signup ? (
      <SessionProvider>
        <SignUpPage />
      </SessionProvider>
    ) : epallet_login ? (
      <SessionProvider>
        <EpalletLogin sourcePath={source_path} />
      </SessionProvider>
    ) : pathname === "/checkout" ? (
      <>
        <Component {...pageProps} />
        <VerifyEmailModal checkoutModal={true} />
      </>
    ) : (
      <Layout
        layoutData={layoutData}
        withoutHeaderProps={pageProps?.withoutHeader}
        withoutFooterProps={pageProps?.withoutFooter}
        withoutSlickBannerProps={pageProps?.withoutSlickBanner}
      >
        <Component {...pageProps} />
        <VerifyEmailModal />
      </Layout>
    );
  if (typeof window !== "undefined") {
    gaScrolling();
  }

  useEffect(() => {
    // When the route changes, start the progress bar
    const handleRouteStart = () => {
      NProgress.start();
    };
    const handleRouteDone = () => {
      NProgress.done();
    };

    Router.events.on("routeChangeStart", handleRouteStart);
    Router.events.on("routeChangeComplete", handleRouteDone);
    Router.events.on("routeChangeError", handleRouteDone);
    const hotJARID = parseInt(process.env.NEXT_PUBLIC_HOTJAR_KEY_ID as any, 10);
    const hotJARCode = parseInt(process.env.NEXT_PUBLIC_HOTJAR_CODE as any, 10);

    hotjar.initialize(hotJARID as number, hotJARCode as number);
    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off("routeChangeStart", handleRouteStart);
      Router.events.off("routeChangeComplete", handleRouteDone);
      Router.events.off("routeChangeError", handleRouteDone);
    };
  }, []);

  useEffect(() => {
    const prevPath = localStorage.getItem("currentPath") || "";
    localStorage.setItem("prevPath", prevPath?.split("?")?.[0] as string);
    localStorage.setItem("currentPath", asPath);
  }, [asPath]);

  const handleRouteChange = async () => {
    window?.dataLayer?.push({
      event: "user_id",
      user_id: userID ? userID : null,
      user_type: userID ? "Registered" : "Guest",
      webVersion: 2,
    });
    const pageUrlString = document.location.href;
    const excludedQueryParam = ["token"];
    const pageUrl = cleanPageURL(pageUrlString, excludedQueryParam);

    window?.dataLayer?.push({
      event: "virtualPageView",
      user_id: userID ? userID : null,
      user_type: userID ? "Registered" : "Guest",
      virtualPageTitle: document.title,
      virtualPageURL: pageUrl,
      webVersion: 2,
    });
  };
  useEffect(() => {
    handleRouteChange();
  }, [router?.asPath]);

  useEffect(() => {
    setMode(
      window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        setMode(event.matches ? "dark" : "light");
      });
  }, []);
  return (
    <>
      <Head>
        {mode == "dark" ? (
          <link
            rel="icon"
            type="image/x-icon"
            href="/favicon_dark.ico"
            id="faviconTag"
          />
        ) : (
          <link
            rel="icon"
            type="image/x-icon"
            href="/favicon.ico"
            id="faviconTag"
          />
        )}

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
      />
      <Provider store={store}>
        <AppProvider>
          <ConfigProvider theme={ePalletTheme}>
            <NoInternetConnection> 
              {LayoutComponent}
              <DefaultZipCode />
              <ExpirationModal />
            </NoInternetConnection> 
          </ConfigProvider>
        </AppProvider>
      </Provider>
    </>
  );
}

// MyApp.getInitialProps = async () => {
//   const timeTaken = new Date();
//   try {
//     const { data: globalComponents } = await requestGlobalComponents();
//     return { layoutData: globalComponents };
//   } catch (err: any) {
//     const now = Date.now();
//     const duration = now - timeTaken.getTime();
//     console.error(
//       "App error - unable to load global components from XM",
//       err,
//       "duration:",
//       duration
//     );
//     const errMessage = err?.message;
//     return {
//       layoutData: { error: errMessage },
//     };
//   }
// };

MyApp.getInitialProps = async () => {
  return {};
};

export default MyApp;
