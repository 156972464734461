import styles from "../styles.module.scss";
import Link from "next/link";

function TabContent({ content, setMenuOpen }: any) {
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const navigationHandler = (item: any) => {
    const formattedPath = item?.path.replace(/\s+/g, "%20").replace(/&/g, "%26");
    const arrayOfCategory = formattedPath?.split("/");
    const category =
      arrayOfCategory?.length > 3
        ? `${arrayOfCategory[1]}/${arrayOfCategory[2]}`
        : arrayOfCategory[1];
    window?.dataLayer?.push({
      event: "navigation_click",
      item_click: item?.name,
      nav_category:
        category === "Food" || category === "Non-Food"
          ? "Shop by category"
          : "Shop by business",
      nav_category2: category
    });
  };

  const menuItems = Array.isArray(content?.children) ? content.children : [];

  return (
    <div className={styles.tabContent}>
      <div className={styles.ulData}>
        {menuItems?.length > 10 ? (
          <>
            <ul>
              {menuItems?.slice(0, 10)?.map((item: any) => {
                const formattedPath = item?.path.replace(/\s+/g, "%20").replace(/&/g, "%26");
                return (
                  <li key={item?.name} onClick={closeMenu}>
                    <Link href={`/product-list${formattedPath}`}>
                      <a onClick={() => navigationHandler(item)}>
                        {item?.name}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {menuItems?.slice(10, 20)?.map((item: any) => {
                const formattedPath = item?.path.replace(/\s+/g, "%20").replace(/&/g, "%26");
                return (
                  <li key={item?.name} onClick={closeMenu}>
                    <Link href={`/product-list${formattedPath}`}>
                      <a onClick={() => navigationHandler(item)}>
                        {item?.name}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <ul>
            {menuItems?.map((item: any) => {
              const formattedPath = item?.path.replace(/\s+/g, "%20").replace(/&/g, "%26");
              return (
                <li key={item?.name} onClick={closeMenu}>
                  <Link href={`/product-list${formattedPath}`}>
                    <a onClick={() => navigationHandler(item)}>{item?.name}</a>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default TabContent;
