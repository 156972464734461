import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TabContent from "./tabContent";
import { CategoryItem } from "./types";

function HeaderMenuDrawer({
  categoryItem,
  title,
  setMenuOpen = () => {}
}: any) {
  const [defaultActiveKey, setDefaultActiveKey] = useState("");
  const activeItems = categoryItem;

  const item = activeItems?.map((item: CategoryItem) => ({
    label: `${item?.name}`,
    key: item?.name,
    children: (
      <TabContent content={item} title={title} setMenuOpen={setMenuOpen} />
    ),
    disabled: false
  }));

  const calcDefaultActiveKey = () => {
    if (activeItems.length > 0) {
      setDefaultActiveKey(activeItems[0].name);
    }
  };

  useEffect(() => {
    calcDefaultActiveKey();
  }, []);

  item?.unshift({
    label: `Shop by ${title}`,
    key: "",
    children: <></>,
    disabled: true
  });

  return (
    <>
      {defaultActiveKey && (
        <Tabs
          defaultActiveKey={defaultActiveKey}
          tabPosition={"left"}
          items={item}
        />
      )}
    </>
  );
}

export default HeaderMenuDrawer;
