import Header from "@components/shared/Header";
import Footer from "@components/shared/Footer";
import { LayoutProps } from "./types";
import React from "react";
import SlickBanner from "../shared/SlickBanner";

export function Layout({
  children,
  withoutHeaderProps = false,
  withoutFooterProps = false,
  withoutSlickBannerProps = false,
}: LayoutProps) {
  const headerProps = {
    guestLabel: "Please sign-in to see delivered prices.",
    guestUrl: "/",
    loggedInLabel: "Creativechaos",
    loggedInUrl: "/",
    headerLinks: [
      {
        linkLabel: "Contact us",
        linkUrl: "/contact-us",
        components: []
      },
      {
        components: [],
        linkLabel: "Sell on Epallet",
        linkUrl: "/?epallet_signup=true&vendor=true"
      }
    ],
    MoveHaggleLinks: [
      {
        moveLabelForNewUser: "Join Move Logistics",
        moveLabelForExistingUser: "Move Logistics",
        moveLinkUrl: "",
        haggleLabelForNewUser: "   Join Haggle Rewards",
        haggleLabelForExistingUser: "   Haggle Rewards ",
        haggleLinkUrl: "",
        labelForNewUser: "Join Move Logistics",
        labelForExistingUser: "Move Logistics",
        linkUrl: "https://move.epallet.com/",
        components: []
      }
    ],
    logoImage: {
      name: "Main",
      url: "https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/images_storefront/Main_logo.webp",
      altText: "" // Change alt to altText
    },
    signInLabel: "Sign In",
    signUpLabel: "Sign Up",
    HaggleLink: [
      {
        components: [],
        labelForNewUser: "Join Haggle Rewards",
        labelForExistingUser: "Haggle Rewards",
        linkUrl: ""
      }
    ],
    MoveLink: [
      {
        components: [],
        labelForNewUser: "Join Move Logistics",
        labelForExistingUser: "Move Logistics ",
        linkUrl: "https://move-staging.epallet.com/"
      }
    ],
    showBanner: false,
    bannerTitle: "<p>Our annual Buy in Bulk sale is now live. 20% off everything. Use promo code &lsquo;BULKBUY22&rsquo;.</p>\n",
    bannerLinkLabel: "Shop Now",
    bannerLinkUrl: "/",
    signInLink: "https://login-sbx.epallet.com/signin",
    signUpLink: "https://login-sbx.epallet.com/signin/register",
    headerBanner: [
      {
        bannerTitle: "Our annual Buy in Bulk sale is now live. 20% off everything. Use promo code ‘BULKBUY22’.",
        bannerLinkLabel: "Shop Now",
        bannerLinkUrl: "/",
        components: []
      }
    ],
    components: []
  };

  const footerProps = {
    logoImage: {
      name: "Main",
      url: "https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/images_storefront/Main_logo.webp",
      alt: ""
    },
    description: "<p>ePallet is business-to-business only. Please be prepared to provide a valid reseller&#39;s permit (business license) or tax-exempt certificate (for a non-profit organization) and show proof that you are authorized to purchase for said business or organization. In the following states, you also need your FEIN: CT, IN, MA and PA.</p>\n",
    footerHeading: [
      {
        icon: "lock",
        alt: "Safe and Secure Checkout",
        components: [],
        ItemText: "<p>Safe and</p>\n\n<p>Secure Checkout</p>\n"
      },
      {
        icon: "customer-service",
        alt: "Great Customer Service",
        components: [],
        ItemText: "<p>Hassle-free Delivery Coordination</p>\n"
      },
      {
        icon: "guarantee",
        alt: "Best Price Guaranteed",
        components: [],
        ItemText: "<p>Real-time pricing</p>\n"
      },
      {
        icon: "rating",
        alt: "Rating",
        components: [],
        ItemText: "<p>Great Customer Service</p>\n"
      }
    ],
    NewsAndOffers: "News, offers, and more",
    emailPlaceholder: "Sign up to receive email alerts",
    submitButton: "Submit",
    footerLists: [
      {
        listName: "Explore",
        listItems: [
          {
            linkText: "Brands",
            linkUrl: "/brands",
            components: []
          },
          {
            linkText: "About Us",
            linkUrl: "/aboutUs",
            components: []
          },
          {
            linkText: "News Room",
            linkUrl: "/press",
            components: []
          },
          {
            linkText: "Testimonials",
            linkUrl: "/testimonials",
            components: []
          },
          {
            linkText: "Haggle Rewards",
            linkUrl: "/haggle",
            components: []
          },
          // {
          //   linkText: "Move Logistics",
          //   linkUrl: "/move",
          //   components: []
          // }
        ],
        components: []
      },
      {
        listName: "Help Center",
        listItems: [
          {
            linkText: "Orders & Purchases",
            linkUrl: "/help-center?orders-purchases-buyer",
            components: []
          },
          {
            linkText: "Shipping & Delivery",
            linkUrl: "/help-center?shipping-delivery-buyer",
            components: []
          },
          {
            linkText: "Payments & Pricing",
            linkUrl: "/help-center?payments-pricing-buyer",
            components: []
          },
          {
            linkText: "My Account",
            linkUrl: "/help-center?my-account-buyer",
            components: []
          },
          {
            linkText: "Help Center",
            linkUrl: "/help-center",
            components: []
          }
        ],
        components: []
      },
      {
        listName: "My Account",
        listItems: [
          {
            linkText: "Account Information",
            linkUrl: "/account/accountInfo",
            components: []
          },
          {
            linkText: "Manage Users",
            linkUrl: "/account/manageUser",
            components: []
          },
          {
            linkText: "My Orders",
            linkUrl: "/account/myOrders",
            components: []
          },
          {
            linkText: "Shopping Lists",
            linkUrl: "/account/shoppingList",
            components: []
          }
        ],
        components: []
      }
    ],
    socialIconsData: [
      {
        icon: "blue-linkedin",
        url: "https://www.linkedin.com/company/epallet/",
        components: []
      }
    ],
    copyrightText: "<p>&reg; 2024 ePallet.com Intellectual Property.<br />\nAll rights reserved.</p>\n",
    privacyText: "Privacy & Terms Of Use",
    privacyLink: "/terms-of-use",
    explore: [
      {
        linkText: "Brands",
        linkUrl: "/brands",
        components: []
      },
      {
        linkText: "About Us",
        linkUrl: "/aboutUs",
        components: []
      },
      {
        linkText: "News Room",
        linkUrl: "/press",
        components: []
      },
      {
        linkText: "Testimonials",
        linkUrl: "/testimonials",
        components: []
      }
    ],
    helpCentre: [
      {
        linkText: "Orders & Purchases",
        linkUrl: "/help-center?orders-purchases-buyer",
        components: []
      },
      {
        linkText: "Shipping & Delivery",
        linkUrl: "/help-center?shipping-delivery-buyer",
        components: []
      },
      {
        linkText: "Payments & Pricing",
        linkUrl: "/help-center?payments-pricing-buyer",
        components: []
      },
      {
        linkText: "Navigation",
        linkUrl: "/help-center?navigation-buyer",
        components: []
      },
      {
        linkText: "My Account",
        linkUrl: "/help-center?my-account-buyer",
        components: []
      },
      {
        linkText: "Membership",
        linkUrl: "/help-center?membership-buyer",
        components: []
      }
    ],
    myAccount: [
      {
        linkText: "Account Information",
        linkUrl: "/account/accountInfo",
        components: []
      },
      {
        linkText: "Manage Users",
        linkUrl: "/account/manageUser",
        components: []
      },
      {
        linkText: "My Orders",
        linkUrl: "/account/myOrders",
        components: []
      },
      {
        linkText: "Shopping Lists",
        linkUrl: "/account/shoppingList",
        components: []
      }
    ],
    components: []
  };

  const showBanner = process.env.NEXT_PUBLIC_HUBSPOT_ENVIRONMENT !== 'production';

  const slickBannerProps = {
    showBanner,
    bannerTitle: "<p>Our annual Buy in Bulk sale is now live. 20% off everything. Use promo code &lsquo;BULKBUY22&rsquo;.</p>\n",
    bannerLinkLabel: "Shop Now",
    bannerLinkUrl: "/product-list/Food Service/Eggs & Dairy",
    components: []
  };


  return (
    <>
      {!withoutSlickBannerProps && showBanner && <SlickBanner {...slickBannerProps} />}
      {!withoutHeaderProps && <Header {...headerProps} />}
      <div id="root-page">{children}</div>
      {!withoutFooterProps && <Footer {...footerProps} />}
    </>
  );
}
