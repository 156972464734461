import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button, Alert, notification, Tooltip } from "antd";
import Link from "next/link";
import router, { useRouter } from "next/router";
import Image from "next/image";
import classnames from "classnames";
import CustomButton from "@components/shared/Button";
import MiniCart from "./MiniCart";
import User from "./User";
import styles from "./styles.module.scss";
import MobileMenu from "./MobileMenu";
import HeaderMenuDrawer from "./Categories/HeaderMenuDrawer";
import Icon from "@components/shared/Icon";
import { MenuDrawerMapProps, menuDrawerMapType, navBarProps } from "./types";
import Search from "./Search";
import LoginModal from "./LoginModal";
import { AppContext } from "../../../../libs/context";
import useOnClickOutside from "~/src/utilities/hooks/useOutsideClick";
import { getCookie } from "src/server/utils/commonUtils";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import { getText } from "@src/libs/resources";
import useClient from "~/src/server/utils/client";
import ShoppingLists from "~/src/components/organsim/AccountPage/ShoppingList";
import axios from "axios";
import qs from "qs";

const businessTitle = "business";
const categoryTitle = "category";

const Navbar = ({
  logoImage,
  signInLabel,
  signInLink,
  signUpLabel,
  signUpLink,
  guestLabel,
  guestUrl,
  loggedInLabel,
  loggedInUrl,
  headerLinks,
  openSearch,
  MoveHaggleLinks,
}: navBarProps) => {
  const ref = useRef(null);
  const searchRef = useRef<any>(null);
  const {
    guestUser,
    setBusinessMenu,
    setCategoryMenu,
    customerStatus,
    setMenuData,
    setOpenMiniCart,
    setBrandsList,
    setOpenMenuSearch,
    setCategoryMenuOpen,
    isCategoryMenuOpen,
    setInputValue,
    openMenuSearch,
    setStoreSearchValue,
    setContent,
    setCurrent,
  } = useContext<any>(AppContext);
  const client = useClient({});
  const { setIsSessionExpired } = useContext<any>(AppContext);
  const [Business, setBusinessCatalog] = useState([]);
  const [category, setCategoryCatalog] = useState([]);
  const [BrandData, setBrandDataCatalog] = useState<any>();
  const [emailVerified, setEmailVerified] = useState<boolean>();
  const [openMenuType, setOpenMenuType] = useState("");
  const [open, setOpen] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState<boolean>();
  const windowWidth = useWindowResize();

  const resendValidationEmail = async () => {
    try {
      let data;
      data = await client.basic.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
      const reqBody = {
        email: data?.data?.email,
      };
      await axios.post(
        `/api/ERP/request-link?type=EMAIL_VERIFICATION`,
        qs.stringify(reqBody),
      );
      notification.success({
        message: `An e-mail has been sent in order to validate your address! `,
        duration: 4,
      });
    } catch (error: any) {
      if(error.response && error.response.status === 429){
        notification.error({
          message: 'Too Many Requests',
          description: 'You have made too many requests. Please try again later.',
          duration: 4,
        });
      } else{
          notification.error({
            message: 'Error',
            description: 'An error occurred while sending the validation email.',
            duration: 4,
        });
      }
    }
  };
  const showDrawer = () => {
    setOpen(true);
    setCategoryMenuOpen(true);
    setOpenMiniCart(false);
    setOpenMenuSearch(false);
    document.body.style.overflow = "hidden";
  };

  const onClose = () => {
    setOpen(false);
    setCategoryMenuOpen(false);
    document.body.style.overflow = "auto";
  };

  const changeOpacity = (value: string) => {
    const element = document?.getElementById("root-page");
    if (element) {
      element.style.opacity = value;
    }
  };

  const closeMenu = () => {
    document.body.style.overflow = "auto";
    changeOpacity("1");
    setOpenMenuType("");
  };

  const menuToggle = (type: string) => () => {
    changeOpacity("0.3");
    setOpenMenuType((priv) => (priv === type ? "" : type));
  };

  const getMenuData = async () => {
    try {
      const { data } = await axios.get("/api/ERP/common/categories/common-categories");
      setMenuData({ data });
      // log the menu data
      console.log(data);

      // Extract and map Shop by Business
      const businessCategories = data.shop_by_business?.map((business: any) => ({
        name: business.name,
        children: business.child.map((child: string) => ({
          name: child,
          path: `/${business.name}/${child.replace(/\s+/g, "%20").replace(/&/g, "%26")}`
        }))
      }));
      setBusinessCatalog(businessCategories);
      setBusinessMenu(businessCategories);

      // Extract and map Shop by Category
      const categoryCategories = data.shop_by_category?.map((category: any) => ({
        name: category.name,
        children: category.child.map((child: string) => ({
          name: child,
          path: `/${category.name}/${child.replace(/\s+/g, "%20").replace(/&/g, "%26")}`
        }))
      }));
      setCategoryCatalog(categoryCategories);
      setCategoryMenu(categoryCategories);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  const getBrandData = async () => {
    let response;
    try {
        response = await axios.get(`/api/ERP/brandList`, {
          headers: { sessionId: getCookie("sessionid") },
        });
        setBrandDataCatalog(response?.data);
        setBrandsList(response?.data);
      } catch (error: any) {}
  };

  useOnClickOutside(ref, () => {
    if (openMenuType) {
      closeMenu();
    }
  });

  useEffect(() => {
    getBrandData();
    getMenuData();
    setIsGuestUser(guestUser);
  }, []);
  useEffect(() => {
    setEmailVerified(customerStatus?.email_verified);
  }, [customerStatus]);

  const route = useRouter();

  const getCustomerStatus = async () => {
    try {
      const response = await client.basic.get("/api/ERP/customer/status", {
        headers: { sessionid: getCookie("sessionid") },
      });
      if (!guestUser && !response?.data?.is_authenticated) {
        setIsSessionExpired(true);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const path = route?.pathname?.split("/");
    if (path[1] !== "account") {
      getCustomerStatus();
    }
    setCategoryMenuOpen(false);
    if (route?.pathname?.split("/")[1] !== "search") {
      setInputValue("");
    }
    if (route?.asPath?.split("/")[2] !== "brands") {
      setStoreSearchValue("");
    }
  }, [route]);

  useEffect(() => {
    const handleRouteDone = () => {
      closeMenu();
    };

    router.events.on("routeChangeComplete", handleRouteDone);

    return () => {
      router.events.off("routeChangeComplete", handleRouteDone);
    };
  }, []);

  const MenuDrawerMap = useCallback(
    ({ type }: MenuDrawerMapProps): JSX.Element => {
      const menuDrawerMap: menuDrawerMapType = {
        category: (
          <HeaderMenuDrawer title={categoryTitle} categoryItem={category} />
        ),
        business: Business && (
          <HeaderMenuDrawer title={businessTitle} categoryItem={Business} />
        )
      };

      return menuDrawerMap?.[type as keyof menuDrawerMapType] || null;
    },
    [Business, BrandData]
  );

  const MenuBtnMap = useMemo(
    () => [
      {
        type: "category",
        title: categoryTitle,
      },
      {
        type: "business",
        title: businessTitle,
      },
    ],
    [Business, BrandData]
  );

  return typeof isGuestUser === "undefined" ? (
    <></>
  ) : (
    <div className={classnames(styles.navbarWrapper, "bg-grey")}>
      <div className={classnames(styles.navbarContainer, "container")}>
        <div className={styles.firstContainer} style={{ flexBasis: "60%" }}>
          {logoImage?.url && (
            <Link href="/">
              <a
                className={styles.logoImg}
                aria-label="navigate to home page"
                onClick={onClose}
              >
                <Image
                  src={logoImage?.url}
                  width="160"
                  height="48"
                  alt="ePallet-logo"
                />
              </a>
            </Link>
          )}

          <div className={styles.desktopMenu}>
            {MenuBtnMap?.map(({ type, title }) => (
              <button
                key={title}
                onClick={menuToggle(type)}
                className={openMenuType === type ? styles.menuCategoryBtn : ""}
              >
                Shop by {title}
                <Icon name="down" width={14} height={14} />
              </button>
            ))}

            <button onClick={() => router.push('/brands')}>
              <span className="ms-2">{getText()?.Menu?.shopByBrand}</span>
            </button>
            <button onClick={() => router.push('/mixed-pallet')}>
              <span className="ms-2">{getText()?.Menu?.buildMixedPallet}</span>
            </button>
          </div>
        </div>
        <div
          className={`${styles.secondContainer} ${
            route.asPath === "/haggle" && isGuestUser && styles.haggleContainer
          }`}
          style={{ flexBasis: "39%" }}
        >
          <div className="w-100">
            {!isCategoryMenuOpen && <Search searchRef={searchRef} />}
          </div>
          <div className={openSearch ? styles.hide : styles.itemsWrapper}>
            {!open && !openSearch ? (
              !isGuestUser ? (
                <>
                  {!isCategoryMenuOpen && !openMenuSearch && (
                    <>
                      <User />
                      {!(
                        !customerStatus?.is_vendor &&
                        !customerStatus?.profile_completed
                      ) && (
                        <Tooltip title="Shopping List" placement="bottom">
                          <button
                            className="p-0 bg-transparent"
                            style={{ border: 0 }}
                          >
                            <Link href="/account/shoppingList">
                              <a
                                onClick={() => {
                                  setOpenMenuSearch(false);
                                  setOpenMiniCart(false);
                                  setContent(<ShoppingLists />);
                                  setCurrent("shoppingList");
                                }}
                                className={styles.wishListIcon}
                                aria-label="Go to shopping list"
                              >
                                <Icon name="wishlist" width={24} height={24} />
                              </a>
                            </Link>
                          </button>
                        </Tooltip>
                      )}
                      <MiniCart className={styles.cart} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {signInLabel &&
                    route.asPath !== "/haggle" &&
                    route.asPath !== "/move" && (
                      <Link
                        href={`/?epallet_login=true&source_path=${router?.asPath?.replace(
                          "&",
                          "__"
                        )}`}
                      >
                        <a className={styles.signLink}>
                          <CustomButton
                            theme="secondary"
                            className={`${styles.headerBtn}`}
                          >
                            {signInLabel}
                          </CustomButton>
                        </a>
                      </Link>
                    )}
                  {signUpLabel &&
                    route.asPath !== "/haggle" &&
                    route.asPath !== "/move" && (
                      <Link href={"/?epallet_signup=true"}>
                        <a className={styles.signLink}>
                          <CustomButton
                            theme="primary"
                            className={`${styles.headerBtn}`}
                          >
                            {signUpLabel}
                          </CustomButton>
                        </a>
                      </Link>
                    )}
                </>
              )
            ) : (
              <></>
            )}
            {!openSearch && !openMenuSearch && (
              <Button
                onClick={open ? onClose : showDrawer}
                className={styles.menuBtn}
                aria-label="menu"
                icon={
                  open ? (
                    <Icon
                      name="cross"
                      width={18}
                      height={18}
                      color="#ffffff"
                      dir={"bottom"}
                    />
                  ) : (
                    <Icon name="menu" width={18} height={18} color="#ffffff" />
                  )
                }
              />
            )}
          </div>
          {open && !openSearch && (
            <MobileMenu
              guestLabel={guestLabel}
              categoryData={category}
              guestUrl={guestUrl}
              loggedInLabel={loggedInLabel}
              loggedInUrl={loggedInUrl}
              headerLinks={headerLinks}
              businessData={Business}
              brandData={BrandData}
              open={open}
              setOpen={setOpen}
              onClose={onClose}
              MoveHaggleLinks={MoveHaggleLinks}
            />
          )}

          {!open &&
            isGuestUser &&
            !openSearch &&
            route.asPath !== "/haggle" &&
            route.asPath !== "/move" && (
              <LoginModal
                signUpLabel={signUpLabel}
                signUpLink={signUpLink}
                signInLink={signInLink}
                signInLabel={signInLabel}
                buttonText="Log in"
                dialogMessage="Please sign-in or sign up to add product in your Shopping List"
              />
            )}
        </div>
        {openMenuType && (
          <div className={styles.tabMenuContainer} ref={ref}>
            <MenuDrawerMap type={openMenuType} />
          </div>
        )}
      </div>
      {typeof emailVerified === "undefined" ? null : !isGuestUser &&
        !emailVerified ? (
        <Alert
          type="warning"
          showIcon={true}
          className={styles.alertContainer}
          message={
            <span className={` ${windowWidth < 992 ? "font-medium-xs" : ""}`}>
              {getText()?.General?.Messages?.alertMessage}
            </span>
          }
          banner={true}
          action={
            <CustomButton
              theme="link"
              className={`mx-10 ${styles["alert-button"]} ${
                windowWidth < 992 ? "font-medium-xs" : ""
              }`}
              onClick={() => {
                resendValidationEmail();
              }}
            >
              Resend Validation Email
            </CustomButton>
          }
          closable
        />
      ) : null}
    </div>
  );
};

export default Navbar;
