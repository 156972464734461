type Environment = "staging" | "production";

type HubspotResource = {
  [key in Environment]: string | null;
};

type ResourceType = "modules" | "tables" | "rows";

type HubspotResources = {
  modules: Record<string, HubspotResource>;
  tables: Record<string, HubspotResource>;
  rows: Record<string, HubspotResource>;
};

const hubspotModules: HubspotResources = {
  // Module IDs
  modules: {
    aboutUsBrandBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183936144141
      staging: "183936144141",
      // Production: https://app.hubspot.com/design-manager/22338452/modules/185160566914
      production: "185160566914",
    },
    aboutUsHeroBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183935004743
      staging: "183935004743",
      // Production: https://app.hubspot.com/design-manager/22338452/modules/185162230181
      production: "185162230181",
    },
    aboutUsJoinOurTeamSection1: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183935915051
      staging: "183935915051",
      // Production: https://app.hubspot.com/design-manager/22338452/modules/185162627456
      production: "185162627456",
    },
    aboutUsJoinOurTeamSection2: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183981812787
      staging: "183981812787",
      // Production: https://app.hubspot.com/design-manager/22338452/modules/185161326001
      production: "185161326001",
    },
    aboutUsOverviewCards: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184038911836
      staging: "184038911836",
      // Production: https://app.hubspot.com/design-manager/22338452/modules/185162231210
      production: "185162231210",
    },
    benefitsCards: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184607668396
      staging: "184607668396",
      production: "185161528085", 
    },
    brands: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183933873983
      staging: "183933873983",
      production: "185161527731", // TODO: Add prod ID
    },
    brandsCategoryBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183933230467
      staging: "183933230467",
      production: "185162231418", // TODO: Add prod ID
    },
    contactUs: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184402401357
      staging: "184402401357",
      production: "185162231666", // TODO: Add prod ID
    },
    featuredVendors: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184542032977
      staging: "184542032977",
      production: "185161528886", // TODO: Add prod ID
    },
    footer: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183978432146
      staging: "183978432146",
      production: "183741507181", // TODO: Add prod ID
    },
    haggleMobileSection: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184649395998
      staging: "184649395998",
      production: "185162231671", // TODO: Add prod ID
    },
    hero: {
        // Staging: https://app.hubspot.com/design-manager/39592841/modules/183934922491
        staging: "184722096776",
        production: "185161528102", // TODO: Add prod ID
        },
    homeOverviewCards: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184722096948
      staging: "184722096948",
      production: "185161327384", // TODO: Add prod ID
    },
    homePromotionBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184723369042
      staging: "184723369042",
      production: "185161327385", // TODO: Add prod ID
    },
    homeSimplySellMore: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184721062976
      staging: "184721062976",
      production: "185162231690", // TODO: Add prod ID
    },
    mixedPalletBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184541010010
      staging: "184541010010",
      production: "185162232333", // TODO: Add prod ID
    },
    orderConfirmation: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184368455241
      staging: "184368455241",
      production: "185161529424", // TODO: Add prod ID
    },
    press: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183931213751
      staging: "183931213751",
      production: "183793116798", // TODO: Add prod ID
    },
    promotionHaggleBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184649539204
      staging: "184649539204",
      production: "185161329542", // TODO: Add prod ID
    },
    promotionHomeBanner: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184361902736
      staging: "184361902736",
      production: "184359974692", // TODO: Add prod ID
    },
    requestProduct: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/184723369003
      staging: "184723369003",
      production: "185162231689", // TODO: Add prod ID
    },
    testimonial: {
      // Staging: https://app.hubspot.com/design-manager/39592841/modules/183978432165
      staging: "183978432165",
      production: "183710298433", // TODO: Add prod ID
    },
    termsAndConditions: {
        // Staging: https://app.hubspot.com/design-manager/39592841/modules/1184062021983
        staging: "184062021983",
        production: "183947038546", // TODO: Add prod ID
    },
    HaggleBanner: {
        // Staging: https://app.hubspot.com/design-manager/39592841/modules/184607157813
        staging: "184607157813",
        production: "185161528083", // TODO: Add prod ID
    },
  },

  // Table IDs
  tables: {
    cardSectionTable: {
      // Staging: https://app.hubspot.com/hubdb/39592841/table/105896340
      staging: "105896340",
      production: "106884167", // TODO: Add prod ID
    },
    carouselTable: {
      // Staging: https://app.hubspot.com/hubdb/39592841/table/105227020
      staging: "105227020",
      production: "106884173", // TODO: Add prod ID
    },
    haggleTable: {
      // Staging: https://app.hubspot.com/hubdb/39592841/table/104409270
      staging: "104409270",
      production: "103996071", // TODO: Add prod ID
    },
    helpCenterBuyerVendorTable: {
        // Staging: https://app.hubspot.com/hubdb/39592841/table/105896340
        staging: "105896718",
        production: "106874199", // TODO: Add prod ID
    },
    helpCenterFAQTable: {
        // Staging: https://app.hubspot.com/hubdb/39592841/table/105896340
        staging: "105897397",
        production: "106884163", // TODO: Add prod ID
    },
    HelpCenterBannerTable: {
        // Staging: https://app.hubspot.com/hubdb/39592841/table/105896340
        staging: "105895973",
        production: "106874197", // TODO: Add prod ID
    },
    SlickBannerTable: {
        // Staging: https://app.hubspot.com/hubdb/39592841/table/105896340
        staging: "104409269",
        production: "103996069", // TODO: Add prod ID
    },
        

  },

  // Row IDs
  rows: {
    cardSectionWelcomeBack: {
      // Staging: Row ID for "Welcome Back"
      staging: "184679121231",
      production: "185164348278", // TODO: Add prod ID
    },
    cardSectionTopCategories: {
      // Staging: Row ID for "Top Categories"
      staging: "184721198441",
      production: "185164348279", // TODO: Add prod ID
    },
    cardSectionShopByBrands1: {
      // Staging: Row ID for "Shop by Brands"
      staging: "184721198463",
      production: "185164348280", // TODO: Add prod ID
    },
    cardSectionShopByBrands2: {
      // Staging: Row ID for "Shop by Brands"
      staging: "184859859315",
      production: "185164348281", // TODO: Add prod ID
    },
    cardSectionShopByBusiness: {
        // Staging: Row ID for "Shop by Business"
        staging: "184721642530",
        production: "185164348282", // TODO: Add prod ID
    },
    carouselFeaturedProductsHome: {
      // Staging: Row ID for "Featured Products"
      staging: "184721198471",
      production: "185157637195", // TODO: Add prod ID
    },
    carouselHaggleProducts: {
      // Staging: Row ID for "Haggle Products"
      staging: "184721198472",
      production: "185157637196", // TODO: Add prod ID
    },
    CarouselPopularThisMonth: {
        // Staging: Row ID for "Popular This Month"
        staging: "184721198473",
        production: "185157637197", // TODO: Add prod ID
    },
    CarouselFeaturedProduct2: {
        // Staging: Row ID for "Featured Products"
        staging: "184721198480",
        production: "185157637198", // TODO: Add prod ID
    },
    CarouselRecentlyViewed: {
        // Staging: Row ID for "Recently Viewed"
        staging: "184721198486",
        production: "185157637199", // TODO: Add prod ID
    },
    CarouselRecomendedForYouOrder: {
        // Staging: Row ID for "Recomended For You"
        staging: "184373212748",
        production: "185157637194", // TODO: Add prod ID
    },
  },
};

export const getResourceId = (
  type: ResourceType,
  key: string
): string => {
  const env = process.env.NEXT_PUBLIC_HUBSPOT_ENVIRONMENT as Environment || "staging";
  const resource = hubspotModules[type][key];
  if (!resource) throw new Error(`Resource key "${key}" not found in type "${type}".`);
  return resource[env] || "";
};

export default hubspotModules;
